import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

import "../../assets/sass/components/collapse.scss";
import QuestionContext from "../../context/QuestionContext";
import { useContext, useState } from "react";

import "./end-view.scss";

const EndView = (props: { question: any }) => {
  const questionContext = useContext(QuestionContext);
  const questions = questionContext.questions.filter(
    (question: any) => question.questionId > 1
  );
  return (
    <>
      <div
        className="submit step wizard-step current animate__animated animate__fadeInRight"
        id="end"
      >
        <div className="summary">
          <div className="container mb-4">
            <div className="">
              <h3>Resultado de tu encuensta</h3>
              {questions.map((question: any, index: number) => {
                return (
                  <div className="summary-page" key={index}>
                    <div className="questions-container">
                      <div className="question">
                        <div className="question-title">
                          {index + 1}- {question.title}
                        </div>
                        <div className="question-response">
                          {question.response
                            ? question.response
                            : "No respondida"}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EndView;
