const SurveyUnavailable = () => {
  return (
    <>
      <div
        className="submit step wizard-step current animate__animated animate__fadeInRight"
        id="end"
      >
        <div className="summary">
          <div className="mb-4">
            <div className="">
              <h3>Lo sentimos, esta encuesta no está disponible</h3>
              <p>
                La encuesta ha sido despublicada por el usuario o no está
                disponible en este momento. Por favor, inténtalo más tarde o
                contacta al administrador de la encuesta para obtener más
                información.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyUnavailable;
