import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getQuestionsTypeList = () => {
  return axios
    .get(`${API_URL}/v1/public/catalog/question/type-list`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.status;
    });
};
