import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import "semantic-ui-css/semantic.min.css";
import { TransitionablePortal } from "semantic-ui-react";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal";
import QuestionContext from "../../context/QuestionContext";
import { ensureAbsoluteUrl } from "../../utils/text.util";
import "./modal-survey.style.scss";

const TIME_EXPIRATION = 10;

const ModalSurvey = (props: { isVisibleModal: boolean }) => {
  const [counter, setCounter] = useState(TIME_EXPIRATION);
  const questionContext = useContext(QuestionContext);
  const {
    surveyInfo: { isTabletModeSurvey, isActiveRedirectUrl, redirectUrl },
  } = questionContext;

  const defaultRedirectUrl = "https://empati.cl";
  const redirectUrlValid = redirectUrl ? redirectUrl : defaultRedirectUrl;

  useEffect(() => {
    if (props.isVisibleModal) {
      setCounter(TIME_EXPIRATION);
      setTimeout(() => document.body.classList.add("modal-fade-in"), 0);
      setTimeout(() => {
        if (!isTabletModeSurvey) {
          const url = isActiveRedirectUrl
            ? redirectUrlValid
            : defaultRedirectUrl;
          window.location.href = ensureAbsoluteUrl(url);
          return;
        }
        return window.location.reload();
      }, 10000);
    }
  }, [props.isVisibleModal]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(+timer);
  }, [counter]);

  return (
    <div className="animate__animated animate__fadeIn">
      <TransitionablePortal
        open={props.isVisibleModal}
        onOpen={() =>
          setTimeout(() => document.body.classList.add("modal-fade-in"), 0)
        }
        transition={{ animation: "scale", duration: 500 }}
      >
        <Modal open={true}>
          <Modal.Content>
            <div className="overlay__inner">
              <Icon color="green" name="check" size="massive" />
              <h1 className="overlay__title">¡Gracias por tu tiempo!</h1>
              <p className="overlay__description">
                ¡Las respuestas de la encuesta han <br />
                sido recibidas con mucho entusiasmo!
              </p>
              <span>Serás redirigido de vuelta en {counter} segundos..</span>
              <button
                type="button"
                name="forward"
                className="forward d-block mx-auto mt-3"
                onClick={() => {
                  if (!isTabletModeSurvey) {
                    const url = isActiveRedirectUrl
                      ? redirectUrlValid
                      : defaultRedirectUrl;
                    window.location.href = ensureAbsoluteUrl(url);
                    return;
                  }
                  window.location.reload();
                }}
              >
                Redireccionar ahora
                <FontAwesomeIcon
                  className="ml-1"
                  icon={faHome}
                  style={{ color: "#fff" }}
                />
              </button>
            </div>
          </Modal.Content>
        </Modal>
      </TransitionablePortal>
    </div>
  );
};

export default ModalSurvey;
