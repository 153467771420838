import { faSquarePollHorizontal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import logoDark from "../../assets/img/logos//dark-large-logo.png";
import { default as logoLight } from "../../assets/img/logos/light-large-logo.png";
import ContentWizard from "../../components/ContentWizard/ContentWizard";
import QuestionContext from "../../context/QuestionContext";
import useScreenSize from "../../hooks/useScreenSize";
import Footer from "./Footer";
import Header from "./Header";
import "./layout-1.scss";

const Layout1 = () => {
  const { width } = useScreenSize();
  const questionContext = useContext(QuestionContext);
  const { surveyInfo, editMode, stepWizard, progress, questions } =
    questionContext;

  const urlRedirect =
    surveyInfo.channel === "DEMO"
      ? "javascript:void(0)"
      : "https://www.empati.cl";

  const getLogoSurvey = () => {
    if (surveyInfo.logoUrl) return surveyInfo.logoUrl;
    return surveyInfo.mainBgType === "light" ? logoDark : logoLight;
  };

  return (
    <>
      {surveyInfo.channel !== "DEMO" && <Header></Header>}
      <div
        className={`${
          editMode ? "container-edit" : "container"
        } animate__animated animate__fadeInLeft`}
      >
        <div id="form_container">
          <div className="row no-gutters">
            {width >= 1200 && (
              <div className={"col-lg-4"}>
                <div
                  id="left_form"
                  className={"bg-color-" + surveyInfo.mainBgType}
                >
                  <div>
                    <img
                      className="img-fluid img-wizard-layout d-none d-md-inline ml-auto"
                      src={getLogoSurvey()}
                      alt=""
                    />
                    <img
                      className="img-fluid img-wizard-layout d-inline d-md-none"
                      src={getLogoSurvey()}
                      alt=""
                    />
                  </div>
                  <h2>
                    <span
                      className={
                        surveyInfo.mainBgType === "light"
                          ? "title_dark"
                          : "title_light"
                      }
                    >
                      {surveyInfo.name}
                    </span>
                  </h2>
                  {/* <p
                    className={
                      surveyInfo.mainBgType === "light"
                        ? "title_dark"
                        : "title_light"
                    }
                  >
                    Encuesta para validar la satisfacción del uso de nuestra
                    página web
                  </p> */}

                  {surveyInfo.channel !== "DEMO" &&
                    surveyInfo.plan?.planId === 1 && (
                      <>
                        <small className="d-block mt-5">
                          Crea encuestas como esta
                        </small>
                        <a
                          href={urlRedirect}
                          className="btn_1 rounded yellow purchase mt-1"
                          target="_parent"
                        >
                          Crear Gratis{" "}
                          <FontAwesomeIcon
                            icon={faSquarePollHorizontal}
                            className="ml-1"
                          />
                        </a>
                        <a
                          href={urlRedirect}
                          className="btn_1 rounded mobile_btn yellow mt-1"
                        >
                          Crear Gratis{" "}
                          <FontAwesomeIcon
                            icon={faSquarePollHorizontal}
                            className="ml-1"
                          />
                        </a>
                      </>
                    )}
                </div>
              </div>
            )}
            <div className={width <= 1200 ? "col-12" : "col-lg-8"}>
              <div
                id="wizard_container"
                className={"bg-color-" + surveyInfo.contentBgType}
              >
                <div id="top-wizard">
                  <div
                    id="progressbar"
                    className="ui-progressbar ui-widget ui-widget-content ui-corner-all"
                    role="progressbar"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    aria-valuenow={progress}
                  >
                    <div
                      className="ui-progressbar-value ui-widget-header ui-corner-left"
                      style={{ width: progress + "%" }}
                    ></div>
                  </div>
                  <span id="location">
                    {stepWizard ? stepWizard : 1} de {questions.length}{" "}
                    {surveyInfo.channel !== "DEMO" && "completado"}
                  </span>
                </div>
                <ContentWizard></ContentWizard>
              </div>
            </div>
          </div>
        </div>
      </div>
      {surveyInfo.channel !== "DEMO" && <Footer></Footer>}
    </>
  );
};

export default Layout1;
