import "./landing-page.scss";

import surveyResponsedImage1 from "../assets/img/illustrations/survey-responsed-1.png";
import surveyResponsedImage2 from "../assets/img/illustrations/survey-responsed-2.png";
import surveyResponsedImage3 from "../assets/img/illustrations/survey-responsed-3.png";

import Header from "../layouts/layout1/Header";

const images = [
  surveyResponsedImage1,
  surveyResponsedImage2,
  surveyResponsedImage3,
];
const SurveyResponsed = () => {
  const randomImage = images[Math.floor(Math.random() * images.length)];

  const redirectToEmpati = () => {
    window.location.href = "https://www.empati.cl";
  };

  return (
    <>
      <Header />

      <div className="landing-page">
        {/* <header>
          <div className="container">
            <a href="#" className="logo">
              Your <b>Website</b>
            </a>
            <ul className="links">
              <li>Home</li>
              <li>About Us</li>
              <li>Work</li>
              <li>Info</li>
              <li>Get Started</li>
            </ul>
          </div>
        </header> */}

        <div className="content">
          <div className="container">
            <div className="info">
              <h1 className="mt-0">¡Encuesta respondida!</h1>
              <h2 className="mt-0"> ¡Gracias por tu valioso feedback!</h2>
              <p>
                Realmente apreciamos que te hayas tomado el tiempo para
                compartir tus pensamientos con nosotros. Tu opinión es crucial
                para mejorar continuamente y asegurarnos de que estamos
                cumpliendo con tus expectativas. ¡Tus comentarios son una parte
                esencial de nuestro proceso de mejora y crecimiento!
              </p>
              <button onClick={redirectToEmpati}>Conocer Empati</button>
            </div>
            <div className="image">
              <img src={randomImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyResponsed;
