import { faSquarePollHorizontal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import QuestionContext from "../context/QuestionContext";
import Footer from "../layouts/layout1/Footer";
import Header from "../layouts/layout1/Header";

const SurveyResult = () => {
  const questionContext = useContext(QuestionContext);
  const {
    surveyInfo,
    surveyInfo: { plan },
  } = questionContext;
  const urlRedirect =
    surveyInfo.channel === "DEMO"
      ? "javascript:void(0)"
      : "https://www.empati.cl";

  return (
    <>
      <Header></Header>

      {plan?.planId === 1 && (
        <>
          <div
            className="parallax_window_in bg_content_no_available"
            data-parallax="scroll"
            data-natural-width="1120"
            data-natural-height="400"
          >
            <div id="sub_content_in">
              <h1>{questionContext.resultText}</h1>
              <p>
                Mejora la experiencia de tus usuarios generando encuestas con{" "}
                <b>empati</b>
              </p>
              <small className="d-block mt-5">Crea encuestas como esta</small>
              <a
                href={urlRedirect}
                className="btn_1 rounded yellow purchase mt-1"
                target="_parent"
              >
                Crear gratis{" "}
                <FontAwesomeIcon
                  icon={faSquarePollHorizontal}
                  className="ml-1"
                />
              </a>
            </div>
          </div>

          <main id="general_page">
            <div className="container margin_60_35">
              <div className="main_title_2">
                <span>
                  <em></em>
                </span>
                <h2>Crea encuestas como esta en empati</h2>
                <p>
                  Mide y mejora la satisfacción de tus clientes y colaboradores
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <a className="box_topic" href="#0">
                    <span>
                      <img
                        src="img/prevention_icon_1.svg"
                        width="70"
                        height="70"
                        alt=""
                      />
                    </span>
                    <h3>Wash Hands</h3>
                    <p>
                      Id mea congue dictas, nec et summo mazim impedit. Vim te
                      audiam impetus interpretaris.
                    </p>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6">
                  <a className="box_topic" href="#0">
                    <span>
                      <img
                        src="img/prevention_icon_2.svg"
                        width="70"
                        height="70"
                        alt=""
                      />
                    </span>
                    <h3>Cover Coughs</h3>
                    <p>
                      Id mea congue dictas, nec et summo mazim impedit. Vim te
                      audiam impetus interpretaris.
                    </p>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6">
                  <a className="box_topic" href="#0">
                    <span>
                      <img
                        src="img/prevention_icon_3.svg"
                        width="70"
                        height="70"
                        alt=""
                      />
                    </span>
                    <h3>Use Face Mask</h3>
                    <p>
                      Id mea congue dictas, nec et summo mazim impedit. Vim te
                      audiam impetus interpretaris.
                    </p>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6">
                  <a className="box_topic" href="#0">
                    <span>
                      <img
                        src="img/prevention_icon_4.svg"
                        width="70"
                        height="70"
                        alt=""
                      />
                    </span>
                    <h3>Avoid Touching Eyes</h3>
                    <p>
                      Id mea congue dictas, nec et summo mazim impedit. Vim te
                      audiam impetus interpretaris.
                    </p>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6">
                  <a className="box_topic" href="#0">
                    <span>
                      <img
                        src="img/prevention_icon_5.svg"
                        width="70"
                        height="70"
                        alt=""
                      />
                    </span>
                    <h3>Keep Distance</h3>
                    <p>
                      Id mea congue dictas, nec et summo mazim impedit. Vim te
                      audiam impetus interpretaris.
                    </p>
                  </a>
                </div>
                <div className="col-lg-4 col-md-6">
                  <a className="box_topic" href="#0">
                    <span>
                      <img
                        src="img/prevention_icon_6.svg"
                        width="70"
                        height="70"
                        alt=""
                      />
                    </span>
                    <h3>Desinfect Surfaces</h3>
                    <p>
                      Id mea congue dictas, nec et summo mazim impedit. Vim te
                      audiam impetus interpretaris.
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <Footer></Footer>
          </main>
        </>
      )}
    </>
  );
};

export default SurveyResult;
