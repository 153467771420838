import { HashRouter, Route, Routes } from "react-router-dom";
import "./assets/sass/menu.scss";
import "./assets/sass/style.scss";
import QuestionState from "./context/QuestionState";
import NotAvailable from "./pages/NotAvailable";
import SurveyContainer from "./pages/SurveyContainer";
import SurveyEmpty from "./pages/SurveyEmpty";
import SurveyResponsed from "./pages/SurveyResponsed";
import SurveyResult from "./pages/SurveyResult";

const App = () => {
  return (
    <>
      <HashRouter>
        <QuestionState>
          <Routes>
            <Route path="/" element={<NotAvailable />} />
            <Route path="/survey-finished" element={<SurveyResponsed />} />
            <Route
              path="encuesta/:surveyId/:channel"
              element={<SurveyContainer />}
            />
            <Route path="/survey-result" element={<SurveyResult />} />
            <Route path="/survey-empty" element={<SurveyEmpty />} />
            <Route path="*" element={<NotAvailable />} />
          </Routes>
        </QuestionState>
      </HashRouter>
    </>
  );
};

export default App;
