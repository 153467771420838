import { faSquarePollHorizontal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import logoDark from "../../assets/img/logos//dark-large-logo.png";
import logoLight from "../../assets/img/logos/light-large-logo.png";
import QuestionContext from "../../context/QuestionContext";

const Header = () => {
  const questionContext = useContext(QuestionContext);
  const { surveyInfo } = questionContext;
  const urlRedirect =
    surveyInfo.channel === "DEMO"
      ? "javascript:void(0)"
      : "https://www.empati.cl";

  return (
    <header>
      <div className="container-fluid d-flex">
        <a href={urlRedirect}>
          <img
            className="img-fluid img-header-layout d-none d-md-inline ml-auto"
            height={45}
            src={surveyInfo.mainBgType === "light" ? logoDark : logoLight}
            alt=""
          />
          <img
            className="img-fluid img-header-layout d-inline d-md-none"
            height={45}
            src={surveyInfo.mainBgType === "light" ? logoDark : logoLight}
            alt=""
          />
        </a>
        {surveyInfo.channel !== "DEMO" && (
          <div className="d-block ml-auto">
            <a
              href={urlRedirect}
              className="btn_1 rounded yellow purchase mt-1"
              target="_parent"
            >
              Crear Mi Encuesta
              <FontAwesomeIcon icon={faSquarePollHorizontal} className="ml-1" />
            </a>
            <a
              href={urlRedirect}
              className="btn_1 rounded mobile_btn yellow mt-1"
            >
              Crear Mi Encuesta
              <FontAwesomeIcon icon={faSquarePollHorizontal} className="ml-1" />
            </a>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
