import { useContext, useEffect, useRef, useState } from "react";
import QuestionContext from "../../context/QuestionContext";
import Tabs from "../ui/Tab/Tab";
import QuestionForm from "./QuestionForm";

type TabsType = {
  label: string;
  index: number;
  Component: any;
  isVisible: boolean;
}[];

const QuestionEdit = () => {
  const divRef: any = useRef(null);
  const questionContext = useContext(QuestionContext);
  const { isVisibleEditForm, isVisibleCreateForm } = questionContext;

  useEffect(() => {
    if (isVisibleEditForm || isVisibleCreateForm)
      divRef.current.scrollIntoView({ behavior: "smooth" });
  });

  const tabs: TabsType = [
    {
      label: isVisibleEditForm ? "⚙️ Editar" : "🚀 Nueva Pregunta",
      index: 1,
      Component: QuestionForm,
      isVisible: true,
    },
    // {
    //   label: "Opciones",
    //   index: 2,
    //   Component: QuestionForm,
    //   isVisible: isVisibleEditForm ? true : false,
    // },
    // {
    //   label: "Mover",
    //   index: 3,
    //   Component: QuestionForm,
    //   isVisible: isVisibleEditForm ? true : false,
    // },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0].index);

  return (
    <>
      <div ref={divRef}></div>
      <div className="mb-3">
        <Tabs selectedTab={selectedTab} onClick={setSelectedTab} tabs={tabs} />
      </div>
    </>
  );
};

export default QuestionEdit;
