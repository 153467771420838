const FirstView = (props: { question: any }) => {
  return (
    <>
      <div
        className="submit step wizard-step current animate__animated animate__fadeInRight"
        id="end"
      >
        <div className="summary">
          <div className="wrapper">
            {/* <h3>{props.question.titulo}</h3> */}
            <p>{props.question.title}</p>
          </div>
          {/* <div className="text-center">
                    <div className="form-group terms">
                        <label className="container_check">Please accept our <a href="#" data-toggle="modal" data-target="#terms-txt">Terms and conditions</a> before Submit
                            <input type="checkbox" name="terms" value="Yes" className="required" />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div> */}
        </div>
      </div>
    </>
  );
};

export default FirstView;
