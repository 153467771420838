import logo from "../assets/img/logos/light-large-logo.png";

const LoadingPage = () => {
  return (
    <>
      <div className="loader-container dark">
        <div className="loader animated-fade-in">
          <img
            className="img-fluid d-block mx-auto"
            height={45}
            src={logo}
            alt=""
          />
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <small className="d-block mt-2">Cargado Encuesta...</small>
        </div>
      </div>
    </>
  );
};

export default LoadingPage;
