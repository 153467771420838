import { useContext } from "react";
import logo from "../../assets/img/logos/dark-large-logo.png";
import QuestionContext from "../../context/QuestionContext";

const Header = () => {
  const questionContext = useContext(QuestionContext);
  const {
    surveyInfo: { plan },
  } = questionContext;

  const urlRedirect =
    questionContext.channel === "DEMO"
      ? "javascript:void(0)"
      : "https://www.empati.cl";

  return (
    <header>
      {plan?.planId === 1 && (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <a href={urlRedirect}>
                <img
                  src={logo}
                  alt=""
                  className="d-none d-md-block img-fluid img-header-layout ml-auto"
                />
                <img
                  src={logo}
                  alt=""
                  className="d-block d-md-none img-fluid img-header-layout"
                />
              </a>
            </div>
            {/* <div className="col-9">
                        <div id="social">
                            <ul>
                                <li><a href="#0"><i className="icon-facebook"></i></a></li>
                                <li><a href="#0"><i className="icon-twitter"></i></a></li>
                                <li><a href="#0"><i className="icon-google"></i></a></li>
                                <li><a href="#0"><i className="icon-linkedin"></i></a></li>
                            </ul>
                        </div>
                        <a href="#0" className="cd-nav-trigger">Menu<span className="cd-icon"></span></a>
                        <nav>
                            <ul className="cd-primary-nav">
                                <li><a href="without_branch_layout_2.html" className="animated_link">Questionnaire without branch</a></li>
                                <li><a href="with_branch_layout_2.html" className="animated_link">Questionnaire with branch</a></li>
                                <li><a href="prevention.html" className="animated_link">Prevention Tips</a></li>
                                <li><a href="faq.html" className="animated_link">Faq</a></li>
                                <li><a href="contacts.html" className="animated_link">Contact Us</a></li>
                                <li><a href="#0" className="animated_link">Purchase Template</a></li>
                            </ul>
                        </nav>
                    </div> */}
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
