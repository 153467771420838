import "./landing-page.scss";

import notFoundImage2 from "../assets/img/illustrations/not-found-2.gif";
import notFoundImage3 from "../assets/img/illustrations/not-found-3.png";
import notFoundImage4 from "../assets/img/illustrations/not-found-4.png";
import Header from "../layouts/layout1/Header";

const images = [notFoundImage2, notFoundImage3, notFoundImage4];
const NotAvailable = () => {
  const randomImage = images[Math.floor(Math.random() * images.length)];

  const redirectToEmpati = () => {
    window.location.href = "https://www.empati.cl";
  };

  return (
    <>
      <Header />

      <div className="landing-page">
        {/* <header>
          <div className="container">
            <a href="#" className="logo">
              Your <b>Website</b>
            </a>
            <ul className="links">
              <li>Home</li>
              <li>About Us</li>
              <li>Work</li>
              <li>Info</li>
              <li>Get Started</li>
            </ul>
          </div>
        </header> */}

        <div className="content">
          <div className="container">
            <div className="info">
              <h1>No se encontró la encuesta</h1>
              <p>
                Lamentamos el inconveniente, la encuesta que intentas acceder no
                existe o no está disponible en este momento. Te invitamos a
                utilizar Empati para crear y administrar de manera eficiente tus
                propias encuestas.
              </p>
              <button onClick={redirectToEmpati}>Conocer Empati</button>
            </div>
            <div className="image">
              <img src={randomImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotAvailable;
