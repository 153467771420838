const MultipleChoice = (props: { question: any }) => {
  return (
    <>
      <div className="step">
        <h3 className="main_question">
          <i className="arrow_right"></i>
          {props.question.title}
        </h3>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label className="container_check version_2">
                China
                <input
                  type="checkbox"
                  name="question_1[]"
                  value="China"
                  className="required"
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="form-group">
              <label className="container_check version_2">
                South Korea
                <input
                  type="checkbox"
                  name="question_1[]"
                  value="South Korea"
                  className="required"
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="form-group">
              <label className="container_check version_2">
                Iran
                <input
                  type="checkbox"
                  name="question_1[]"
                  value="Iran"
                  className="required"
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="container_check version_2">
                Europe
                <input
                  type="checkbox"
                  name="question_1[]"
                  value="Europe"
                  className="required"
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="form-group">
              <label className="container_check version_2">
                US States
                <input
                  type="checkbox"
                  name="question_1[]"
                  value="US States"
                  className="required"
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="form-group">
              <label className="container_check version_2">
                None of the above
                <input
                  type="checkbox"
                  name="question_1[]"
                  value="Mobile Design"
                  className="required"
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipleChoice;
