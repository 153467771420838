export const SAVE_QUESTIONS = "SAVE_QUESTIONS";
export const SAVE_WIZARD = "SAVE_WIZARD";
export const SAVE_PROGRESS = "SAVE_PROGRESS";
export const SAVE_ISLOADING_QUESTIONS = "SAVE_ISLOADING_QUESTIONS";
export const SAVE_ISLOADING_FORM = "SAVE_ISLOADING_FORM";
export const SAVE_ISVISIBLE_EDIT_FORM = "SAVE_ISVISIBLE_EDIT_FORM";
export const SAVE_ISVISIBLE_CREATE_FORM = "SAVE_ISVISIBLE_CREATE_FORM";
export const SAVE_SURVEY_INFO = "SAVE_SURVEY_INFO";
export const SAVE_RESPONSE = "SAVE_RESPONSE";
export const SAVE_IS_VALID_SURVEY = "SAVE_IS_VALID_SURVEY";
export const SAVE_IS_SUBMIT = "SAVE_IS_SUBMIT";
export const SAVE_IS_EMPTY_SURVEY = "SAVE_IS_EMPTY_SURVEY";
export const SAVE_EDIT_MODE = "SAVE_EDIT_MODE";
export const SAVE_IS_ERROR_SEND_SURVEY = "SAVE_IS_ERROR_SEND_SURVEY";
export const SAVE_IS_SUCCESS_SEND_SURVEY = "SAVE_IS_SUCCESS_SEND_SURVEY";
export const SAVE_SURVEY_SESSION_ID = "SAVE_SURVEY_SESSION_ID";
export const SAVE_QUESTION_TYPES = "SAVE_QUESTION_TYPES";
