import "./InfoAlert.css";

interface InfoAlertProps {
  heading: string;
  message: string;
  footer: string;
}

const InfoAlert: React.FC<InfoAlertProps> = ({ heading, message, footer }) => {
  return (
    <div className="info-alert">
      <h4 className="info-alert-heading">{heading}</h4>
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
      <hr className="info-alert-divider" />
      <p className="info-alert-footer">{footer}</p>
    </div>
  );
};

export default InfoAlert;
