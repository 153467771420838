import { useContext } from "react";
import QuestionContext from "../../context/QuestionContext";

const Footer = () => {
  const questionContext = useContext(QuestionContext);
  const {
    surveyInfo,
    surveyInfo: { plan },
  } = questionContext;

  const urlEmpatiRedirect =
    surveyInfo.channel === "DEMO"
      ? "javascript:void(0)"
      : "https://www.empati.cl";
  const urlHwareRedirect =
    surveyInfo.channel === "DEMO"
      ? "javascript:void(0)"
      : "https://www.hware.cl/team";

  const currentTime = new Date();
  const year = currentTime.getFullYear();

  return (
    <>
      <div className="container">
        <footer id="home" className="clearfix">
          <p>© {year} Empati</p>
          {plan?.planId === 1 && (
            <ul>
              <li>
                <a href={urlEmpatiRedirect} className="animated_link">
                  Crea una encuesta como esta
                </a>
              </li>
              <li>
                <a href={urlHwareRedirect} className="animated_link">
                  Equipo humanware
                </a>
              </li>
            </ul>
          )}
        </footer>
      </div>
    </>
  );
};

export default Footer;
