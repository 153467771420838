import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getQuestionsByIdSurvey = (id: number) => {
  return axios
    .get(`${API_URL}/v1/public/survey/${id}/questions`)
    .then((res) => {
      if (!res.data || res.data?.status?.code !== 200) return "EMPTY";
      if (res.data?.data?.questions?.length < 1) return "EMPTY";

      return res.data;
    })
    .catch((e) => {
      if (e.response?.status === 400) return "EMPTY";
      return "ERROR";
    })
    .finally(() => {
      return "ERROR";
    });
};

export const createQuestionSurvey = (request: any, token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(`${API_URL}/survey/question`, request, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch(() => {
      return null;
    });
};

export const updateQuestionSurvey = (
  request: any,
  token: string,
  questionId: number
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .put(`${API_URL}/survey/question/${questionId}`, request, {
      headers,
    })
    .then((res) => {
      return res;
    })
    .catch(() => {
      return null;
    });
};

export const saveResponseSurvey = (request: any, token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(`${API_URL}/v1/public/survey/questions/responses`, request, {
      headers,
    })
    .then(() => {
      return "OK";
    })
    .catch(() => {
      return "ERROR";
    });
};

export const generateSurveySession = (request: any, token: string) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return axios
    .post(`${API_URL}/v1/public/survey/session`, request, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.status;
    });
};
