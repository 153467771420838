import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import QuestionContext from "../context/QuestionContext";
import Layout1 from "../layouts/layout1/Layout1";
import Layout2 from "../layouts/layout2/Layout2";
import Layout3 from "../layouts/layout3/Layout3";
import LoadingPage from "./LoadingPage";

const SurveyContainer = () => {
  const { surveyId, channel } = useParams();
  const questionContext = useContext(QuestionContext);
  const { surveyInfo, saveSurveyInfo, isEmptySurvey } = questionContext;
  const navigate = useNavigate();

  useEffect(() => {
    questionContext.getQuestions(surveyId, channel);
    questionContext.getQuestionTypes();
  }, []);

  const iframeBindingPanel = () => {
    window.onmessage = function (e) {
      if (e.data && e.data?.type === "GENERAL") {
        const isFirstQuestion = e.data.isFirstQuestion
          ? e.data.isFirstQuestion
          : false;

        const editMode = e.data.editMode ? e.data.editMode : false;
        const token = e.data.token ? e.data.token : null;

        questionContext.saveEditMode(editMode, token, isFirstQuestion);

        if (isFirstQuestion && questionContext.questions.length < 1) {
          const request = { data: { isVisibleCreateForm: true } };
          validateWhenActionIsNewQuestion(request);
          questionContext.saveEmptySurvey(false);
        }
      }

      if (e.data && e.data?.type === "FORMAT_TYPE") {
        saveSurveyInfo({
          ...surveyInfo,
          formatType: e.data?.formatType
            ? e.data.formatType
            : surveyInfo.formatType,
        });
      }

      if (e.data && e.data?.type === "MAIN_BG_TYPE") {
        saveSurveyInfo({
          ...surveyInfo,
          mainBgType: e.data?.mainBgType
            ? e.data.mainBgType
            : surveyInfo.mainBgType,
        });
      }

      if (e.data && e.data?.type === "CONTENT_BG_TYPE") {
        saveSurveyInfo({
          ...surveyInfo,
          contentBgType: e.data?.contentBgType
            ? e.data.contentBgType
            : surveyInfo.contentBgType,
        });
      }

      if (e.data && e.data?.type === "QUESTION") {
        const question = e.data.question ? e.data.question : null;
        const isVisibleEditForm = e.data.isVisibleEditForm
          ? e.data.isVisibleEditForm
          : false;

        questionContext.saveQuestion(question);
        questionContext.updateStepWizard(question.orden);
        questionContext.saveIsVisibleEditForm(isVisibleEditForm);
      }

      if (e.data && e.data?.type === "NEW_QUESTION") {
        questionContext.isVisibleCreateForm === false &&
          validateWhenActionIsNewQuestion(e);
      }

      if (e.data && e.data?.type === "RESET") {
        window.location.reload();
      }
    };
  };

  const validateWhenActionIsNewQuestion = (e: any) => {
    const isVisibleCreateForm = e.data.isVisibleCreateForm
      ? e.data.isVisibleCreateForm
      : false;

    const newQuestion = {
      questionId: null,
      options: [],
      title: null,
    };
    const questions = [...questionContext.questions, ...[newQuestion]];

    questionContext.saveQuestions(questions);
    questionContext.updateStepWizard(questions.length);
    questionContext.saveIsVisibleCreateForm(isVisibleCreateForm);
  };

  return (
    <>
      {iframeBindingPanel()}
      {!questionContext.isLoadingQuestions ? (
        <div className="h-100">
          {surveyInfo.formatType === "layout-1" && <Layout1 />}
          {surveyInfo.formatType === "layout-2" && <Layout2 />}
          {surveyInfo.formatType === "layout-3" && <Layout3 />}
        </div>
      ) : (
        <LoadingPage></LoadingPage>
      )}
    </>
  );
};

export default SurveyContainer;
