import * as CryptoJS from "crypto-js";
const secretKey = "3mp471&h4m4nwr3";

export const encryptMessage = (message: any) => {
  const cipherText = CryptoJS.AES.encrypt(message, secretKey);
  return cipherText;
};

export const decryptMessage = (message: any) => {
  const bytes = CryptoJS.AES.decrypt(message, secretKey);
  const decrypted = bytes.toString(CryptoJS.enc.Utf8);
  return decrypted;
};
