import "../../../assets/sass/components/form-inputs.scss";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
interface SelectProps {
  options: any;
  label: string;
  value?: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  isTooltipActive?: boolean;
  tooltipText?: string;
}

const Select: React.FC<SelectProps> = ({
  options,
  label,
  value,
  onChange,
  disabled = false,
  isTooltipActive = false,
  tooltipText = null,
}) => {
  return (
    <>
      <div className="form-group">
        <label>{label}</label>
        <div className="d-flex">
          <div className="w-100">
            <select
              onChange={(e) => {
                if (disabled === false) return onChange(+e.target.value);
              }}
              className="form-style"
              id="questionType"
              value={value ? value : 0}
              disabled={disabled}
            >
              {options.map((option: any) => (
                <option key={option.id} value={option.id} disabled={disabled}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {isTooltipActive && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "8px",
              }}
            >
              <span>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ color: "#3498db", fontSize: "24px", cursor: "help" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={tooltipText}
                />
                <Tooltip
                  id="my-tooltip"
                  place="left-end"
                  data-tooltip-variant="dark"
                  style={{ width: "300px", fontSize: "12px" }}
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Select;
