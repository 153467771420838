import { faAngleRight, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import QuestionContext from "../../context/QuestionContext";
import {
  createQuestionSurvey,
  updateQuestionSurvey,
} from "../../services/survey.service";
import InfoAlert from "../InfoAlert/InfoAlert";
import Csat from "../questionTypes/Csat/Csat";
import Nps from "../questionTypes/Nps/Nps";
import Select from "../ui/Select/Select";
import Textarea from "../ui/Textarea/Textarea";
import ToggleSwitch from "../ui/ToggleSwitch/ToggleSwitch";

const TOOLTIPO_TEXT_SCALE_1 =
  "• Escala de 1 a 3: Esta escala es más sencilla y puede ser ideal para preguntas más simples o cuando se prefiere una respuesta más directa.";
const TOOLTIPO_TEXT_SCALE_2 =
  "• Escala de 1 a 5: Este tipo de escala permite una evaluación más detallada, ideal para cuando se requiere una precisión más alta en las respuestas.";
const TOOLTIPO_TEXT_MANDATORY =
  "• Pregunta obligatoria: Si activas esta opción, los encuestados deberán responder obligatoriamente a esta pregunta para poder continuar con la encuesta. Se recomienda utilizarla en preguntas cruciales cuya respuesta es necesaria para el análisis de los resultados.";
const TOOLTIPO_TEXT_COMMENT =
  "• Habilitar comentarios: Al activar esta opción, los encuestados podrán dejar un comentario o proporcionar detalles adicionales en relación a su respuesta. Esto puede ser útil para recopilar más información o entender mejor las razones detrás de sus calificaciones.";

const QuestionForm = () => {
  const questionContext = useContext(QuestionContext);
  const { questions, isVisibleCreateForm, surveyInfo } = questionContext;
  const [actualQuestion, setActualQuestion] = useState({
    ...questions[questionContext.stepWizard - 1],
  });

  const [isLoadingForm, setIsLoadingForm] = useState(false);

  const [questionTitle, setQuestionTitle] = useState(
    actualQuestion.title ? actualQuestion.title : ""
  );
  const [questionType, setQuestionType] = useState(
    actualQuestion.typeId ? actualQuestion.typeId : 1
  );
  const [questionScale, setQuestionScale] = useState(
    actualQuestion.optionScale ? actualQuestion.optionScale : 2
  );
  const [typeCSAT, setTypeCSAT] = useState(
    actualQuestion.questionTypeFormId ? actualQuestion.questionTypeFormId : 1
  );
  const [typeNPS, setTypeNPS] = useState(
    actualQuestion.questionTypeFormId ? actualQuestion.questionTypeFormId : 1
  );

  const [isMandatory, setIsMandatory] = useState(
    actualQuestion.isMandatory ? actualQuestion.isMandatory : false
  );
  const [commentAvailable, setCommentAvailable] = useState(
    actualQuestion.commentAvailable ? actualQuestion.commentAvailable : false
  );

  const [errorForm, setErrorForm] = useState({
    questionTitle: {
      isValid: true,
      errorMessage: "Debes ingresar la pregunta",
    },
  });

  useEffect(() => {
    if (isVisibleCreateForm) {
      setActualQuestion({});
      setQuestionTitle("");
      setQuestionType(1);
      setQuestionScale(2);
      setTypeCSAT(1);
      setCommentAvailable(false);
      setTypeNPS(1);
    }
  }, [isVisibleCreateForm]);

  useEffect(() => {
    setActualQuestion({ ...actualQuestion, optionScale: questionScale });
  }, [questionScale]);

  useEffect(() => {
    setActualQuestion({ ...actualQuestion, questionTypeFormId: typeCSAT });
  }, [typeCSAT]);

  useEffect(() => {
    setActualQuestion({ ...actualQuestion, questionTypeFormId: typeNPS });
  }, [typeNPS]);

  useEffect(() => {
    setActualQuestion({ ...actualQuestion, isMandatory });
  }, [isMandatory]);

  useEffect(() => {
    setActualQuestion({ ...actualQuestion, commentAvailable });
  }, [commentAvailable]);

  const handleCancel = () => {
    if (questionContext.isVisibleCreateForm) {
      const questions = questionContext.questions;
      questions.pop();
      questionContext.updateStepWizard(questionContext.stepWizard - 1);
      questionContext.saveQuestions(questions);
    }

    questionContext.saveIsVisibleEditForm(false);
    questionContext.saveIsVisibleCreateForm(false);
  };

  const saveQuestionFetch = () => {
    if (validateForm()) return;

    const request: any = {
      questionTitle,
      questionType,
      questionScale,
      questionTypeForm: questionType === 1 ? typeCSAT : typeNPS,
      surveyId: questionContext.surveyInfo.surveyId,
      isMandatory: isMandatory,
      commentAvailable: commentAvailable,
    };

    const token = questionContext.token;

    setIsLoadingForm(true);

    if (questionContext.isVisibleCreateForm) {
      createQuestionSurvey(request, token)
        .then((response) => {
          window.parent.postMessage(
            {
              type: "GET_QUESTIONS",
              isSuccessRequest: response !== null,
            },
            "*"
          );
          setIsLoadingForm(false);
          if (response !== null) {
            const { surveyInfo, channel } = questionContext;
            questionContext.getQuestions(surveyInfo.surveyId, channel);
            questionContext.saveIsVisibleEditForm(false);
            questionContext.saveIsVisibleCreateForm(false);
          }
        })
        .catch((error) => {
          setIsLoadingForm(false);
        });
    } else {
      updateQuestionSurvey(request, token, actualQuestion.questionId)
        .then((response) => {
          const { surveyInfo, channel } = questionContext;
          window.parent.postMessage(
            {
              type: "GET_QUESTIONS",
              isSuccessRequest: response !== null,
            },
            "*"
          );
          setIsLoadingForm(false);
          questionContext.getQuestions(surveyInfo.surveyId, channel);
          questionContext.saveIsVisibleEditForm(false);
          questionContext.saveIsVisibleCreateForm(false);
        })
        .catch((error) => {
          setIsLoadingForm(false);
        });
    }
  };

  const validateForm = () => {
    let isError = false;

    if (questionTitle.trim().length < 1) {
      setErrorForm({
        questionTitle: {
          isValid: false,
          errorMessage: "Debes ingresar la pregunta",
        },
      });
    }

    return isError;
  };

  return (
    <>
      <div className="wrapper row">
        {actualQuestion.hasActivity && (
          <div className="mb-3">
            <InfoAlert
              heading="🚨Atención"
              message="Esta pregunta ya ha recibido interacciones y, para mantener la integridad de los datos, <b>no se puede modificar</b>. Si necesitas hacer cambios, considera crear una nueva pregunta."
              footer="Si tienes alguna duda, consulta nuestra sección de preguntas frecuentes o ponte en contacto con nuestro equipo de soporte."
            />
          </div>
        )}
        <div className="col-12 col-md-8">
          <Textarea
            value={questionTitle}
            onChange={setQuestionTitle}
            maxLength={200}
            isError={!errorForm.questionTitle.isValid}
            errorMessage={errorForm.questionTitle.errorMessage}
            disabled={actualQuestion.hasActivity}
          />
        </div>
        <div className="col-12 col-md-4 col-12 col-md-4 mt-3 mt-md-0">
          <Select
            options={questionContext.questionsTypes}
            value={questionType}
            onChange={setQuestionType}
            label="Tipo de pregunta"
            disabled={actualQuestion.hasActivity}
          />
        </div>
      </div>
      <hr className="mx-0" />
      {(questionType === 1 || questionType === 2) && (
        <div className="wrapper row" style={{ marginBottom: "8px" }}>
          <div className="col-12">
            <div className="d-flex justify-content-end">
              <div className="text-right" style={{ marginRight: "32px" }}>
                <label>
                  Pregunta obligatoria{" "}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{
                      color: "#3498db",
                      fontSize: "12px",
                      cursor: "help",
                    }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={TOOLTIPO_TEXT_MANDATORY}
                  />
                  <Tooltip
                    id="my-tooltip"
                    place="left-end"
                    data-tooltip-variant="dark"
                    style={{ width: "300px", fontSize: "12px" }}
                  />
                </label>
                <div>
                  <ToggleSwitch
                    value={isMandatory}
                    handleChange={(value) => setIsMandatory(value)}
                  />
                </div>
              </div>
              <div className="text-right">
                <label>
                  Habilitar comentarios{" "}
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    style={{
                      color: "#3498db",
                      fontSize: "12px",
                      cursor: "help",
                    }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={TOOLTIPO_TEXT_COMMENT}
                  />
                  <Tooltip
                    id="my-tooltip"
                    place="left-end"
                    data-tooltip-variant="dark"
                    style={{ width: "300px", fontSize: "12px" }}
                  />
                </label>
                <div>
                  <ToggleSwitch
                    value={commentAvailable}
                    handleChange={(value) => setCommentAvailable(value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {questionType === 1 && (
        <>
          <div className="wrapper row">
            <div className="col-12 col-md-6">
              <Select
                options={questionContext.scaleCSAT}
                value={questionScale}
                onChange={setQuestionScale}
                label="Escala"
                disabled={actualQuestion.hasActivity}
                isTooltipActive={true}
                tooltipText={
                  questionScale === 1
                    ? TOOLTIPO_TEXT_SCALE_1
                    : TOOLTIPO_TEXT_SCALE_2
                }
              />
            </div>
            <div className="col-12 col-md-6 col-12 col-md-4 mt-3 mt-md-0">
              <Select
                options={questionContext.typeCSAT}
                value={typeCSAT}
                onChange={setTypeCSAT}
                label="Forma"
                disabled={actualQuestion.hasActivity}
              />
            </div>
            <div className="col-12 mt-3">
              <Csat question={actualQuestion} type={typeCSAT} />
            </div>
          </div>
          <hr className="mx-0" />
        </>
      )}
      {questionType === 2 && (
        <>
          <div className="wrapper row">
            <div className="col-12 col-md-6 col-12 col-md-4 mt-3 mt-md-0">
              <Select
                options={questionContext.typeNPS}
                value={typeNPS}
                onChange={setTypeNPS}
                label="Forma"
                disabled={actualQuestion.hasActivity}
              />
            </div>
            <div className="col-12 mt-3">
              <Nps question={actualQuestion} />
            </div>
          </div>
          <hr className="mx-0" />
        </>
      )}

      <div className="">
        {questionContext.isVisibleEditForm &&
          surveyInfo.formatType === "layout-1" && (
            <button
              type="button"
              name="backward"
              className="btn_1 small success mb-md-0 mr-md-3"
              onClick={() => saveQuestionFetch()}
              disabled={actualQuestion.hasActivity}
            >
              Siguiente Pregunta
              <FontAwesomeIcon className="ml-1" icon={faAngleRight} />
            </button>
          )}

        <button
          type="button"
          name="backward"
          className="btn_1 small success float-right"
          onClick={() => saveQuestionFetch()}
          disabled={actualQuestion.hasActivity || isLoadingForm}
        >
          {isLoadingForm ? "Guardando..." : "Guardar"}
        </button>
        {questions.length > 1 && (
          <button
            type="button"
            name="backward"
            className="btn_1 small cancel float-right mb-md-0 mr-md-2"
            onClick={() => handleCancel()}
          >
            Cancelar
          </button>
        )}
      </div>
    </>
  );
};
export default QuestionForm;
